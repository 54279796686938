<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-keyboard-outline',
          title: 'Web Design',
          text: 'Get your site built and customised to reflect your business.',
        },
        {
          icon: 'mdi-human-greeting',
          title: 'Training',
          text: 'Learn best practises and soft skills to use software to maximise your experience.',
        },
        {
          icon: 'mdi-code-braces',
          title: 'Software Development',
          text: 'Software to solve any customer needs and difficulties.',
        },
        {
          icon: 'mdi-puzzle-outline',
          title: 'Application Integration',
          text: 'Custom integrations for seamless data transfer between your systems.',
        },
      ],
    }),
  }
</script>
